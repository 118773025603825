import React from 'react';
import SidebarLink from './SidebarLink';

function ServiceDetailsSidebar() {
  return (
    <div className="col-lg-4">
      <div className="sidebar">
        <ul className="sidebar-category">
          <SidebarLink text="Ploegbaas/meestergast" link="#ploegbaas" />
          <SidebarLink text="Metselaar" link="#metselaar" />
          <SidebarLink text="Betonbekister" link="#betonkister" />
          <SidebarLink text="Allround arbeider" link="#allround" />
          <SidebarLink text="Spontane sollicitatie" link="#spontaan" />
        </ul>


      </div>
    </div>
  );
}

export default ServiceDetailsSidebar;
