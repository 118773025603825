import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { HashLink as Link } from 'react-router-hash-link';
import { SRLWrapper } from 'simple-react-lightbox';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import project1 from '../../assets/images/portfolio/project-1.png';
import project2 from '../../assets/images/portfolio/project-2.png';
import project3 from '../../assets/images/portfolio/project-3.png';
import project4 from '../../assets/images/portfolio/project-4.png';
import project5 from '../../assets/images/portfolio/project-5.png';
import project6 from '../../assets/images/portfolio/project-6.png';
import BrandItem from './BrandItem';
import brandItemData from './brandItemData';

function Projects() {
  return (
    <div className="project-wrapper">
      <div className="project-top">
        <div className="container">
          <div className="top-content d-flex justify-content-between align-items-end flex-wrap pt-4 pb-4">
            <div className="section-head mt-5">

              <h2 className="section-title mb-20">
                Benieuwd naar onze gerealiseerde projecten?<br></br>Neem dan zeker een kijkje!
                <span className="heading-dot" />
              </h2>
            </div>
            <div className="top-right">
              <Link to="/realisaties" className="button button-primary">
                Realisaties
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
