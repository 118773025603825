import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import UltraFeature from './UltraFeature';

function Pricing() {
  return (
    <div className="pricing-wrapper section-padding">
      <div className="container">
        <div className="section-head text-center">
          <span className="heading-bg">Pricing</span>
          <h2 className="section-title">
            Choose your best offer
            <span className="heading-dot" />
          </h2>
        </div>
        <div className="pricing-content">
          <div className="row gy-5 gy-lg-0">
            <div className="col-lg-4 col-md-6">
              <div className="pricing-card">
                <span className="pricing-plan">Basaic Plan</span>
                <div className="pricing-price">
                  <h2 className="price">
                    <span className="price-small-text">$</span>150
                    <span className="price-small-text">
                      / per m<sup>2</sup>
                    </span>
                  </h2>
                  <p className="pricing-text">
                    A good choice for a country house (from 100 m²)
                  </p>
                </div>

                <ul className="plan-features">
                  <li className="features-item">Free house design</li>
                  <li className="features-item">Foundation construction</li>
                  <li className="features-item">
                    The durability of our designs
                  </li>
                  <li className="features-item">
                    Free maintenance and service
                  </li>
                  <li className="features-item">Decorating a country house</li>
                </ul>
                <Link to="#" className="button button-primary">
                  Choose plan
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="pricing-card ultra-card">
                <span className="pricing-plan">Basic Plan</span>
                <div className="pricing-price">
                  <h2 className="price">
                    <span className="price-small-text">$</span>250
                    <span className="price-small-text">
                      / per m<sup>2</sup>
                    </span>
                  </h2>
                  <p className="pricing-text">
                    A good choice for a country house (from 100 m²)
                  </p>
                </div>

                <ul className="plan-features">
                  <UltraFeature feature=" Free maintenance and service" />
                  <UltraFeature feature=" The durability of our designs" />
                  <UltraFeature feature="  Free house design" />
                  <UltraFeature feature=" Decorating a country house" />
                  <UltraFeature feature="  Landscape design" />
                  <UltraFeature feature="  Foundation construction" />
                </ul>
                <Link to="#" className="button button-primary">
                  Choose plan
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="pricing-card">
                <span className="pricing-plan">Pro Plan</span>
                <div className="pricing-price">
                  <h2 className="price">
                    <span className="price-small-text">$</span>350
                    <span className="price-small-text">
                      / per m<sup>2</sup>
                    </span>
                  </h2>
                  <p className="pricing-text">
                    A good choice for a country house (from 100 m²)
                  </p>
                </div>

                <ul className="plan-features">
                  <li className="features-item">Exclusive solutions</li>
                  <li className="features-item">
                    Free interior design project
                  </li>
                  <li className="features-item">
                    Site selection for construction
                  </li>
                  <li className="features-item">
                    Free maintenance and service
                  </li>
                  <li className="features-item">Free Landscaping</li>
                </ul>
                <Link to="#" className="button button-primary">
                  Choose plan
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
