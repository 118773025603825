import bannerBg from '../assets/images/banner-bg.png';
import Interior from '../components/Interior';
import PageBanner from '../components/PageBanner';
import Pricing from '../components/Pricing';
import ServicesSetcion from '../components/ServicesSection';

function Services() {
  return (
    <>
      <PageBanner
        shadowHeading="Services"
        mainHeading="Services"
        currentPage="Services"
        bannerBg={bannerBg}
      />
      <ServicesSetcion />
      <Interior />
      <Pricing />
    </>
  );
}

export default Services;
