import React from 'react';
import avatar2 from '../../assets/images/contact/manager.png';
import avatar1 from '../../assets/images/contact/owner.png';
import ContactForm from './ContactForm';
import OwnerCard from './OwnerCard';

function ContactSection() {
  return (
    <section className="contact-wrapper section-padding">
      <div className="container">
        <div className="row align-items-center gy-5 gy-lg-0">
          <div className="col-lg-6">
            <div className="left-content">
              <div className="section-head">
                <span className="heading-bg">Contact</span>
                <h2 className="section-title">
                  Contacteer ons
                  <span className="heading-dot" />
                </h2>
                <p className="body-text mt-20 mb-30">
                  Zoekt u naar een betrouwbare partner voor uw volgende bouw- of renovatieproject? Neem contact met ons op!
                </p>
              </div>

            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div className="right-content">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactSection;
