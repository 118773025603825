import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import ScrollTopBtn from './components/ScrollTopBtn';
import Overons from './pages/Overons';
// import Blog from './pages/Blog';
// import SingleBlog from './pages/BlogDetails';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Realisaties from './pages/Realisaties';
// import PortfolioDetails from './pages/PortfolioDetails';
import Services from './pages/Services';
import Vacatures from './pages/Vacatures';
import AlgemeneVoorwaarden from './pages/AlgemeneVoorwaarden';
import PrivacyCookies from './pages/PrivacyCookies';
import CookieConsent from './components/CookieConsent';

function App() {
  return (
    <>
      <ScrollTopBtn />
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/overons" element={<Overons />} />
          <Route path="/services" element={<Services />} />
          <Route path="/vacatures" element={<Vacatures />} />
          <Route path="/realisaties" element={<Realisaties />} />
          {/* <Route path="/portfolio-details" element={<PortfolioDetails />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/single-blog" element={<SingleBlog />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/algemene-voorwaarden" element={<AlgemeneVoorwaarden />} />
          <Route path="/privacy-cookies" element={<PrivacyCookies />} />
        </Routes>
        <Footer />
        <CookieConsent />
      </Router>
    </>
  );
}

export default App;
