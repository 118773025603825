import React from 'react';

function ProcessItem({ number, heading, text }) {
  return (
    <div className="col-md-3">
      <div className="process-card">
        <div className="card-divider" />
        <span className="card-num">{number}</span>
        <h3 className="card-heading">{heading}</h3>
        <p className="body-text">{text}</p>
      </div>
    </div>
  );
}

export default ProcessItem;
