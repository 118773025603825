import { v4 as uuidv4 } from 'uuid';
import member1 from '../../assets/images/team/team-member1.png';
import member2 from '../../assets/images/team/team-member2.png';
import member3 from '../../assets/images/team/team-member3.png';

const membersData = [
  {
    id: uuidv4(),
    thumb: member1,
    name: 'Adeline Rivera',
    role: 'Architecture',
    profileLink: '/',
    fbUrl: '/',
    twitterUrl: '/',
    instaUrl: '/',
  },

  {
    id: uuidv4(),
    thumb: member2,
    name: 'Adeline Rivera',
    role: 'Architecture',
    profileLink: '/',
    fbUrl: '/',
    twitterUrl: '/',
    instaUrl: '/',
  },

  {
    id: uuidv4(),
    thumb: member3,
    name: 'Adeline Rivera',
    role: 'Architecture',
    profileLink: '/',
    fbUrl: '/',
    twitterUrl: '/',
    instaUrl: '/',
  },

  {
    id: uuidv4(),
    thumb: member1,
    name: 'Adeline Rivera',
    role: 'Architecture',
    profileLink: '/',
    fbUrl: '/',
    twitterUrl: '/',
    instaUrl: '/',
  },
];

export default membersData;
