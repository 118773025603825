import React, { useState } from 'react';
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhone,
  FaPlus,
  FaSearch,
  FaTwitter
} from 'react-icons/fa';
import { HashLink as Link } from 'react-router-hash-link';
import mainLogo from '../../assets/images/logo-white.svg';
import MobileMenu from '../MobileMenu';

function Header() {
  const [showSearch, setShowSearch] = useState(true);
  const searchHanlder = () => {
    setShowSearch(!showSearch);
  };

  return (
    <header className="header-wrapper">
      <nav className="navbar">
        <div className="navbar-content d-flex">
          <div className="logo-wrapper">
            <Link to="/">
              <img src={mainLogo} alt="Credom" />
            </Link>
          </div>
          <div className="menu-wrapper d-flex flex-column">
            <div className="top-menu d-flex justify-content-between align-items-center">
              <div className="top-menu-left">
                <ul className="contact-info d-flex">
                  <li className="contact-item">
                    <span className="item-icon">
                      <FaPhone />
                    </span>
                    <span className="item-text"><a href="tel:+32494889128">+32 (0)494 88 91 28</a></span>
                  </li>
                  <li className="contact-item">
                    <span className="item-icon">
                      <FaEnvelope />
                    </span>
                    <span className="item-text">
                      <a href="mailto:info@dbaonderneming.be">
                        info@dbaonderneming.be
                      </a>
                    </span>
                  </li>
                  <li className="contact-item">
                    <span className="item-icon">
                      <FaMapMarkerAlt />
                    </span>
                    <span className="item-text">
                      <a
                        href="https://goo.gl/maps/Som6fJPGnUgFBNU2A"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Breëersteenweg 204, 3640 Kinrooi
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="top-menu-right">
                <div className="social-icons">
                  <a
                    href="https://www.instagram.com/dbaonderneming_bouw/"
                    className="social-link"
                  >
                    <span className="social-icon">
                      <FaInstagram />
                    </span>
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=100087923133877"
                    className="social-link"
                  >
                    <span className="social-icon">
                      <i className="fab fa-facebook-f" />
                      <FaFacebookF />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="main-menu d-flex align-items-center justify-content-between">
              <div className="main-menu-left d-flex align-items-center">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/overons">Over ons</Link>
                  </li>
                  <li>
                    <Link to="/#diensten">Diensten</Link>
                  </li>
                  <li>
                    <Link to="/realisaties">Realisaties</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  {/* <li>
                    <Link className='current' to="/vacatures">Vacatures</Link>
                  </li> */}

                  <li>
                    <Link to="/vacatures">
                      Vacatures
                      <span className="icon">
                        <FaPlus />
                      </span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/vacatures#ploegbaas">Ploegbaas/meestergast</Link>
                      </li>
                      <li>
                        <Link to="/vacatures#metselaar">Metselaar</Link>
                      </li>
                      <li>
                        <Link to="/vacatures#betonkister">Betonkister</Link>
                      </li>
                      <li>
                        <Link to="/vacatures#allround">Allround arbeider</Link>
                      </li>
                      <li>
                        <Link to="/vacatures#spontaan">Spontane sollicitatie</Link>
                      </li>
                    </ul>
                  </li>

                </ul>
              </div>
              {/* <div className="main-menu-right d-flex align-items-center"></div> */}
            </div>
          </div>
        </div>
      </nav>
      <MobileMenu />
    </header>
  );
}

export default Header;
