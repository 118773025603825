import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function PageBanner({
  bannerBg,
  shadowHeading,
  mainHeading,
  currentPage,
  nastedPage,
  nastedLink,
  nasted,
}) {
  return (
    <section
      className="section banner-wrapper bg-cover"
      style={{ backgroundImage: `url(${bannerBg})` }}
    >
      <div className="banner-overlay" />
      <div className="container">
        <div className="banner-content">
          <div className="section-head">
            <span className="heading-bg">{shadowHeading}</span>
            <h2 className="section-title">
              {mainHeading}
              <span className="heading-dot" />
            </h2>
          </div>
          <div className="breadcrumb-wrap">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                {nasted ? (
                  <li className="breadcrumb-item">
                    <Link to={nastedLink}>{nastedPage}</Link>
                  </li>
                ) : null}
                <li className="breadcrumb-item active" aria-current="page">
                  {currentPage}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageBanner;
