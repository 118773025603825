import React from 'react';
import { FaCheck } from 'react-icons/fa';

function UltraFeature({ feature }) {
  return (
    <li className="features-item">
      <span className="icon">
        <FaCheck />
      </span>{' '}
      {feature}
    </li>
  );
}

export default UltraFeature;
