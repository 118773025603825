import React from 'react';
import ServiceDetailsSidebar from './ServiceDetailsSidebar';

function ServiceDetails() {
  return (
    <div className="service-details-wrapper section-padding">
      <div className="container">
        <div className="row gy-5 gy-lg-0">
          <div className="col-lg-8">
            <div className="main-content">
              <h2 className="heading-2">Werken bij DBA - Bouw en renovatie</h2>
              <p className="body-text mt-25 mb-25">
                Wij zijn op zoek naar ervaren en gemotiveerde collega’s voor onze uitdagende bouwprojecten. Heb je een passie voor bouwen en ben bekwaam in je vak, dan zijn wij op zoek naar jou!
              </p>
              <p className="body-text mb-25">
                Als deel van ons team zul je verantwoordelijk zijn voor de uitvoering van de bouwprojecten, zowel particulier als professioneel, van begin tot eind. Je zult werken met ons team om te zorgen dat elk project op tijd, binnen budget en tot tevredenheid van onze klanten wordt afgerond.
              </p>

              <p className="body-text mt-50 mb-50">
                Ben jij de gemotiveerde bouwprofessional die wij zoeken? Neem dan zeker eens een kijkje tussen onze vacatures!
              </p>

              <hr></hr>

              <h3 id="ploegbaas" name="ploegbaas" className="heading-3 mt-50 mb-25">Ploegbaas / meestergast</h3>

              <p className="body-text mt-25 mb-25">
                Als ploegbaas ben je verantwoordelijk voor het aansturen van een team van bouwvakkers en zorg je ervoor dat projecten op tijd en binnen budget worden afgerond.
              </p>

              <p className="body-text mt-25 mb-25">
                <strong>Taken en verantwoordelijkheden:</strong>
                <ul>
                  <li>Leiden en motiveren van het bouwteam;</li>
                  <li>Plannen en organiseren van bouwprojecten;</li>
                  <li>Controleren van de kwaliteit en veiligheid van het werk;</li>
                  <li>Contact onderhouden met opdrachtgevers, architecten en leveranciers;</li>
                  <li>Toezicht houden op de naleving van de bouwvoorschriften en -regelgeving;</li>
                  <li>Verantwoordelijk voor de werfadministratie en rapportage.</li>
                </ul>
              </p>

              <p className="body-text mt-25 mb-25">
                <strong>Vereiste vaardigheden en kwalificaties:</strong>
                <ul>
                  <li>Leiderschaps- en organisatorische vaardigheden;</li>
                  <li>Communicatief sterk en in staat om opdrachtgevers en teamleden te informeren en aan te sturen;</li>
                  <li>Technisch inzicht en ervaring met bouwmaterialen en -technieken;</li>
                  <li>In bezit van een rijbewijs B.</li>
                </ul>
              </p>

              <p className="body-text mt-25 mb-25">
                Wij bieden een uitdagende en verantwoordelijke functie binnen een dynamische en groeiende bouwonderneming. Als ploegbaas ben je een belangrijke schakel in het bouwproces en draag je bij aan het succes van onze projecten.
              </p>

              <a class="button button-primary" href="mailto:info@dbaonderneming.be">Hier solliciteren</a>



              <hr className='mt-50'></hr>


              <h3 id="metselaar" name="metselaar" className="heading-3 mt-50 mb-25">Metselaar</h3>

              <p className="body-text mt-25 mb-25">
                Als metselaar ben je verantwoordelijk voor het metselen van muren, gevels en andere bouwconstructies volgens de geldende bouwvoorschriften
              </p>

              <p className="body-text mt-25 mb-25">
                <strong>Taken en verantwoordelijkheden:</strong>
                <ul>
                  <li>Uitvoeren van metselwerkzaamheden zoals het maken van funderingen, muren en gevels;</li>
                  <li>Lezen en begrijpen van bouwtekeningen en -specificaties;</li>
                  <li>Uitvoeren van voegwerk en reparaties aan bestaand metselwerk;</li>
                  <li>Werken met verschillende soorten bouwmaterialen zoals bakstenen, betonblokken en natuursteen;</li>
                </ul>
              </p>

              <p className="body-text mt-25 mb-25">
                <strong>Vereiste vaardigheden en kwalificaties:</strong>
                <ul>
                  <li>Kennis van verschillende soorten bouwmaterialen en -technieken;</li>
                  <li>Zorgvuldigheid en precisie in het uitvoeren van metselwerkzaamheden;</li>
                  <li>In staat om zelfstandig en in teamverband te werken;</li>
                  <li>In bezit van een rijbewijs B.</li>
                </ul>
              </p>

              <p className="body-text mt-25 mb-25">
                Wij bieden een uitdagende en afwisselende functie binnen een dynamische en groeiende bouwonderneming. Als metselaar draag je bij aan het succes van onze projecten en lever je vakmanschap af.
              </p>

              <a class="button button-primary" href="mailto:info@dbaonderneming.be">Hier solliciteren</a>



              <hr className='mt-50'></hr>


              <h3 id='betonkister' name='betonkister' className="heading-3 mt-50 mb-25">Betonkister</h3>

              <p className="body-text mt-25 mb-25">
                Als betonkister ben je verantwoordelijk voor het bekisten en storten van betonconstructies volgens de geldende bouwvoorschriften.
              </p>

              <p className="body-text mt-25 mb-25">
                <strong>Taken en verantwoordelijkheden:</strong>
                <ul>
                  <li>Lezen en begrijpen van bouwtekeningen en -specificaties;</li>
                  <li>Uitvoeren van bekistingswerkzaamheden en plaatsen van wapening;</li>
                  <li>Storten en afwerken van betonconstructies;</li>
                  <li>Controleren van de kwaliteit en veiligheid van het werk;</li>
                  <li>Werken met verschillende soorten bekistingen en bekistingsmaterialen;</li>
                </ul>
              </p>

              <p className="body-text mt-25 mb-25">
                <strong>Vereiste vaardigheden en kwalificaties:</strong>
                <ul>
                  <li>Kennis van verschillende soorten bekistingen en bekistingsmaterialen;</li>
                  <li>Zorgvuldigheid en precisie in het uitvoeren van bekistings- en stortwerkzaamheden;</li>
                  <li>In staat om zelfstandig en in teamverband te werken;</li>
                  <li>In bezit van een rijbewijs B.</li>
                </ul>
              </p>

              <p className="body-text mt-25 mb-25">
                Wij bieden een uitdagende en afwisselende functie binnen een dynamische en groeiende bouwonderneming. Als metselaar draag je bij aan het succes van onze projecten en lever je vakmanschap af.
              </p>

              <a class="button button-primary" href="mailto:info@dbaonderneming.be">Hier solliciteren</a>

              <hr className='mt-50'></hr>


              <h3 id='allround' name='allround' className="heading-3 mt-50 mb-25">Allround arbeider</h3>

              <p className="body-text mt-25 mb-25">
                Als allround arbeider ben je een onmisbare schakel in de bouwsector. Je bent verantwoordelijk voor een breed scala aan taken en draagt bij aan het succesvol voltooien van diverse bouwprojecten.
              </p>

              <p className="body-text mt-25 mb-25">
                <strong>Taken en verantwoordelijkheden:</strong>
                <ul>
                  <li>•	Uitvoeren van diverse werkzaamheden, waaronder metselwerk, het maken van funderingen en gevels.</li>
                  <li>Lezen, begrijpen en nauwkeurig interpreteren van bouwtekeningen en -specificaties.</li>
                  <li>Werken met diverse bouwmaterialen, zoals bakstenen, betonblokken en natuursteen.</li>

                </ul>
              </p>

              <p className="body-text mt-25 mb-25">
                <strong>Vereiste vaardigheden en kwalificaties:</strong>
                <ul>
                  <li>Brede kennis van verschillende soorten bouwmaterialen en -technieken;</li>
                  <li>Zorgvuldigheid en precisie in het uitvoeren van diverse werkzaamheden;</li>
                  <li>Zowel zelfstandig als in teamverband kunnen werken;</li>
                  <li>In het bezit van een rijbewijs B.</li>

                </ul>
              </p>

              <p className="body-text mt-25 mb-25">
                Wij bieden een uitdagende en gevarieerde functie binnen een dynamische en groeiende bouwonderneming. Als allround arbeider lever je een waardevolle bijdrage aan het succes van onze projecten en toon je vakmanschap in al je werkzaamheden.
              </p>

              <a class="button button-primary" href="mailto:info@dbaonderneming.be">Hier solliciteren</a>

              <hr className='mt-50'></hr>


              <h3 id='spontaan' name='spontaan' className="heading-3 mt-50 mb-25">Spontane sollicitatie</h3>

              <p className="body-text mt-25 mb-25">
                Ben jij een gedreven en veelzijdige arbeider op zoek naar nieuwe uitdagingen? Bij ons staan wij altijd open voor getalenteerde professionals die zich willen aansluiten bij ons team. Als jij denkt dat jouw vaardigheden en ervaring een waardevolle aanvulling kunnen zijn op ons bedrijf, nodigen wij je van harte uit om een spontane sollicitatie te doen.
              </p>

              <a class="button button-primary" href="mailto:info@dbaonderneming.be">Hier solliciteren</a>


            </div>
          </div>
          <ServiceDetailsSidebar />
        </div>
      </div>
    </div>
  );
}

export default ServiceDetails;
