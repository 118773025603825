import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function SidebarLink({ text, link, active }) {
  return (
    <li className={active ? 'category-item active' : 'category-item'}>
      <Link to={link} class={active ? 'category-link active' : 'category-link'}>
        {text}
      </Link>
    </li>
  );
}

export default SidebarLink;
