import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import testimonialBg from '../../assets/images/testimonial-illustration.png';
import testimonialData from './testimonialData';
import TestimonialItem from './TestimonialItem';

SwiperCore.use([Navigation]);

function Testimonial() {
  return (
    <div
      className="testimonial-wrapper section-padding"
      style={{ backgroundImage: `url(${testimonialBg})` }}
    >
      <div className="container">
        <div className="section-head">

          <h2 className="section-title">
            Wil jij ons team versterken?
            <span className="heading-dot" />

          </h2>
          <a class="button button-primary mt-50 mb-50" href="/vacatures">Vacatures</a>
        </div>

      </div>
    </div>
  );
}

export default Testimonial;
