import React from 'react';
import cardBg1 from '../../assets/images/services/company-bg.png';
import cardBg2 from '../../assets/images/services/private-bg.png';
import InteriorCard from './InteriorCard';

function Interior() {
  return (
    <section className="interior-wrapper">
      <div className="row">
        <InteriorCard
          cardBg={cardBg1}
          shadaowHeading="Compnay"
          mainHeadidng="Architecture and interior"
          desc="We use technologies that have shown their energy efficiency, reliability and practicality in our work. You decide which one to use."
        />
        <InteriorCard
          cardBg={cardBg2}
          shadaowHeading="Private"
          mainHeadidng="Building a house to order"
          desc=" We will build your dream home for you. We have something to interest you, there is always an offer worthy of your attention."
          cardClr="light"
        />
      </div>
    </section>
  );
}

export default Interior;
