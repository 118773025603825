import { v4 as uuidv4 } from 'uuid';
import brand1 from '../../assets/images/icons/brand1.png';
import brand2 from '../../assets/images/icons/brand2.png';
import brand3 from '../../assets/images/icons/brand3.png';
import brand4 from '../../assets/images/icons/brand4.png';
import brand5 from '../../assets/images/icons/brand5.png';
import brand6 from '../../assets/images/icons/brand6.png';

const brandItemData = [
  {
    id: uuidv4(),
    brand: brand1,
  },
  {
    id: uuidv4(),
    brand: brand2,
  },
  {
    id: uuidv4(),
    brand: brand3,
  },
  {
    id: uuidv4(),
    brand: brand4,
  },
  {
    id: uuidv4(),
    brand: brand5,
  },
  {
    id: uuidv4(),
    brand: brand6,
  },
];

export default brandItemData;
