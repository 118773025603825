import React from 'react';

function Map() {
  return (
    <div className="contact-map-wrapper ">
      <iframe
        title="map"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB55y7uddxvKSxDcwBjRoNd65D9KyknQ0M&q=Breëersteenweg 204, 3640 Kinrooi"
        className="contact-map"
        allowFullScreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      />
    </div>
  );
}

export default Map;
