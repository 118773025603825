import React from 'react';
import About1 from '../components/About1';
import Facts from '../components/Facts';
import Hero from '../components/Hero';
import Numbers from '../components/Numbers';
import Projects from '../components/Projects';
import Queries from '../components/Queries';
import ServicesSection from '../components/ServicesSection';
import Team from '../components/Team';
import Testimonial from '../components/Testimonial';

function Home() {
  return (
    <>
      <Hero />
      <About1 />
      <ServicesSection />
      <Projects />
      <Testimonial />

    </>
  );
}

export default Home;
