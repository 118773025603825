import bannerBg from '../assets/images/DBA/PageHeaders/header_vacatures.jpg';
import PageBanner from '../components/PageBanner';
import ServiceDetails from '../components/ServiceDetails';

function Vacatures() {
  return (
    <>
      <PageBanner
        bannerBg={bannerBg}
        shadowHeading="JOBS"
        mainHeading="Vacatures"
        currentPage="Vacatures"
      />
      <ServiceDetails />
    </>
  );
}

export default Vacatures;
