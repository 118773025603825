import bannerBg from '../assets/images/DBA/PageHeaders/header_contact.jpg';
import ContactSection from '../components/ContactSection';
import Map from '../components/Map/Map';
import PageBanner from '../components/PageBanner';

function Contact() {
  return (
    <>
      <PageBanner
        bannerBg={bannerBg}
        shadowHeading="Contact"
        mainHeading="Contacteer ons"
        currentPage="Contact"
      />
      <ContactSection />
      <Map />
    </>
  );
}

export default Contact;
