import React from 'react';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import {
  FaBehance,
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaPaperPlane,
  FaStopwatch,
  FaTwitter,
  FaYoutube
} from 'react-icons/fa';
import { HashLink as Link } from 'react-router-hash-link';

const MenuContent = () => (
  <div className="mobile-menu-bottom">
    <ul>

      <li className="bottom-item">
        <AiOutlineMail className="icon" />
        info@dbaonderneming.be
      </li>
      <li className="bottom-item">
        <AiOutlinePhone className="icon" />
        +32 (0)494 88 91 28
      </li>
    </ul>
    <div className="bottom-btn text-center">
      <Link to="/contact" className="button button-primary">
        <FaPaperPlane className="icon" />
        Contact
      </Link>
    </div>
    <div className="social-icons">
      <a href="https://www.facebook.com/profile.php?id=100087923133877">
        <FaFacebookF />
      </a>
      <a href="https://www.instagram.com/dbaonderneming_bouw/">
        <FaInstagram />
      </a>
    </div>
  </div>
);

export default MenuContent;
