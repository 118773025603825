import React from 'react';
import ProcessItem from './ProcessItem';

function Process() {
  return (
    <section className="work-process-wrapper section-padding">
      <div className="container text-center">
        <div className="section-head">
          <h2 className="section-title mb-30">
            Onze troeven
            <span className="heading-dot" />
          </h2>
        </div>
        <div className="row">
          <ProcessItem
            number="01"
            heading="Betrouwbaar"
          />
          <ProcessItem
            number="02"
            heading="Kwalitatief"
          />
          <ProcessItem
            number="03"
            heading="Efficiënt"
          />
          <ProcessItem
            number="04"
            heading="Nieuwste werkmethoden"
          />
        </div>
        <h3>Ook lid worden van dit fantastische team?</h3> <a class="button button-primary mt-35" href="/vacatures">Vacatures</a>
      </div>
    </section>
  );
}

export default Process;
