import { v4 as uuidv4 } from 'uuid';
import icon1 from '../../assets/images/icons/medal.png';
import icon4 from '../../assets/images/icons/rocket-launch.png';
import icon2 from '../../assets/images/icons/shield.png';
import icon3 from '../../assets/images/icons/thumb-up.png';

const whyUsData = [
  {
    id: uuidv4(),
    icon: icon1,
    heading: 'Quality assurance',
    text: ' We provide an official quality guarantee for all types of work and services.',
  },

  {
    id: uuidv4(),
    icon: icon2,
    heading: 'Safe materials',
    text: '   We have environmentally friendly and safe materials and technologies.',
  },
  {
    id: uuidv4(),
    icon: icon3,
    heading: 'Qualified specialists',
    text: '   We provide an official quality guarantee for all types of work and services.',
  },
  {
    id: uuidv4(),
    icon: icon4,
    heading: 'Mission company',
    text: ' Terms of implementation and providing a full cycle of work for us is one task',
  },
];

export default whyUsData;
