import React from 'react';

function BrandItem({ brand }) {
  return (
    <div className="slider-item">
      <img src={brand} alt="brand" className="slider-img" />
    </div>
  );
}

export default BrandItem;
