import React from "react"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import { SRLWrapper } from "simple-react-lightbox"
import galleryItem1 from "../../assets/images/portfolio/portfolio-item1.png"

function PortfolioProjectItem({ name, subTitle, thumbImage, images }) {
	return (
		<SRLWrapper>
			<div className="gallery-items">
				<div>
					<a>
						<h3>
							{name}
							<br></br>
							{subTitle}
						</h3>
						<img src={thumbImage} alt={name} className="gallery-img" />
					</a>
				</div>
				{images.map((image, index) => (
					<div style={{ display: "none" }}
						key={index}
					>
						<a href={image}>
							<img src={image} alt={`${name} ${index + 1}`} className="gallery-img" />
						</a>
					</div>
				))}
			</div>
		</SRLWrapper>
	)
}

export default PortfolioProjectItem
