import React from 'react';
import rightImg from '../../assets/images/DBA/Overons/overons_1.jpg';
import pencilIcon from '../../assets/images/icons/pencil.png';

function About2() {
  return (
    <section className="numbers-wrapper about-two-wrapper section-padding">
      <div className="container">
        <div className="row align-items-center gy-5 gy-lg-0">
          <div className="col-lg-6">
            <div className="left-content">
              <div className="section-head">
                <h2 className="section-title mb-20">
                  Welkom bij<br></br>DBA-Onderneming!
                  <span className="heading-dot" />
                </h2>
              </div>
              <h4 className="small-heading pr-80">
                Met 15 jaar ervaring in de bouwsector, staan wij garant voor kwalitatieve en efficiënte projecten.
              </h4>
              <p className="body-text pr-80">
                Vanaf 2019 hebben wij een grote sprong gemaakt in de projectbouw, waaronder appartementsgebouwen, woningwijken en particuliere woningen. Ons bedrijf richt zich ook op kelderbouw, waarbij wij kiezen voor degelijk materiaal en nieuwste werkmethoden. Veiligheid en orde op de werkvloer zijn belangrijke waarden voor ons, evenals het creëren van een fijne groepssfeer. De klant staat bij ons centraal, waarbij wij altijd proberen mee te denken en de wensen van de klant te vervullen.
              </p>
            </div>

          </div>
          <div className="col-lg-6">
            <div className="right-content">
              <div
                className="about-thumb bg-cover"
                style={{ backgroundImage: `url(${rightImg})` }}
              />
              <div className="right-card d-flex align-items-center">
                <img src={pencilIcon} alt="icon" className="card-icon" />
                <p className="body-text">
                  15 jaar ervaring, één missie:<br></br>
                  uw bouwdromen werkelijkheid maken!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About2;
