import bannerBg from '../assets/images/DBA/PageHeaders/header_overons.jpg';
import PageBanner from '../components/PageBanner';

function AlgemeneVoorwaarden() {
  return (
    <>
      <PageBanner
        bannerBg={bannerBg}
        shadowHeading="Algemene Voorwaarden"
        mainHeading="Algemene Voorwaarden"
        currentPage="AlgemeneVoorwaarden"
      />

      <section className="numbers-wrapper about-two-wrapper section-padding">
        <div className="container">
          <div className="row align-items-center gy-5 gy-lg-0">
            <div className="col-lg-12">
              <div className="left-content">
                <div className="section-head">
                  <h2 className="section-title mb-20">
                    Algemene Voorwaarden
                    <span className="heading-dot" />
                  </h2>
                </div>

                <p className="body-text pr-80 mb-25">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. At quis risus sed vulputate odio ut enim blandit volutpat. Congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque. Nulla malesuada pellentesque elit eget gravida. Tincidunt tortor aliquam nulla facilisi cras fermentum odio eu. Hac habitasse platea dictumst vestibulum rhoncus. Pharetra sit amet aliquam id diam. Pellentesque id nibh tortor id. Ultricies integer quis auctor elit sed vulputate mi sit amet. Vitae congue eu consequat ac felis. Diam in arcu cursus euismod quis viverra. Nunc id cursus metus aliquam eleifend mi in nulla. Nulla porttitor massa id neque aliquam. Tempus quam pellentesque nec nam aliquam sem et. Ut morbi tincidunt augue interdum velit euismod in pellentesque massa. Id diam vel quam elementum. Maecenas volutpat blandit aliquam etiam erat velit scelerisque. Morbi quis commodo odio aenean. Amet tellus cras adipiscing enim eu turpis egestas pretium aenean.
                </p>
                <p className="body-text pr-80 mb-25">
                  Rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant. Tincidunt lobortis feugiat vivamus at augue eget. Porttitor eget dolor morbi non. Orci sagittis eu volutpat odio facilisis mauris sit amet massa. Turpis nunc eget lorem dolor. Integer feugiat scelerisque varius morbi enim nunc faucibus a pellentesque. Viverra suspendisse potenti nullam ac tortor vitae purus faucibus. Vehicula ipsum a arcu cursus vitae congue mauris rhoncus aenean. Risus feugiat in ante metus. In hendrerit gravida rutrum quisque non tellus orci. Urna neque viverra justo nec ultrices dui. Vestibulum lectus mauris ultrices eros in. In nisl nisi scelerisque eu ultrices vitae auctor eu. Venenatis cras sed felis eget velit. Vel elit scelerisque mauris pellentesque. Enim nunc faucibus a pellentesque sit amet porttitor eget dolor. Suscipit adipiscing bibendum est ultricies. Adipiscing elit pellentesque habitant morbi tristique.
                </p>
                <p className="body-text pr-80 mb-25">
                  Porttitor rhoncus dolor purus non. Sit amet volutpat consequat mauris. Dui vivamus arcu felis bibendum ut tristique et egestas. Massa tincidunt nunc pulvinar sapien et ligula. Magnis dis parturient montes nascetur ridiculus mus mauris. Sit amet nisl suscipit adipiscing. Mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare. Ut eu sem integer vitae justo eget magna fermentum. Curabitur gravida arcu ac tortor dignissim convallis. Cras adipiscing enim eu turpis egestas pretium. Potenti nullam ac tortor vitae purus. Est lorem ipsum dolor sit. Morbi enim nunc faucibus a pellentesque sit amet. Sollicitudin aliquam ultrices sagittis orci a scelerisque purus.
                </p>
                <p className="body-text pr-80 mb-25">
                  Egestas fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate. Accumsan sit amet nulla facilisi morbi tempus iaculis. Cursus in hac habitasse platea dictumst quisque sagittis. Amet aliquam id diam maecenas ultricies mi. Facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum. Semper auctor neque vitae tempus quam pellentesque. Dolor sit amet consectetur adipiscing elit duis tristique sollicitudin. Congue quisque egestas diam in. Aliquam faucibus purus in massa tempor nec feugiat. Sit amet consectetur adipiscing elit pellentesque.
                </p>
                <p className="body-text pr-80 mb-25">
                  Fermentum leo vel orci porta non pulvinar neque laoreet suspendisse. Justo eget magna fermentum iaculis eu non. Quis commodo odio aenean sed adipiscing. Dolor sit amet consectetur adipiscing elit duis. Ipsum dolor sit amet consectetur adipiscing elit ut. Interdum velit laoreet id donec ultrices tincidunt arcu non sodales. Sodales ut etiam sit amet nisl purus. Sagittis orci a scelerisque purus semper eget duis at. Vitae congue eu consequat ac felis donec et odio pellentesque. Auctor elit sed vulputate mi. Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget egestas. Egestas fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate. Praesent tristique magna sit amet purus gravida quis blandit turpis.
                </p>
              </div>

            </div>

          </div>
        </div>
      </section>

    </>
  );
}

export default AlgemeneVoorwaarden;
