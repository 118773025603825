import { useState } from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaPaperPlane,
  FaTwitter
} from 'react-icons/fa';
import { HashLink as Link } from 'react-router-hash-link';
import emailIcon from '../../assets/images/icons/email.svg';
import mapIcon from '../../assets/images/icons/map-point.svg';
import phoneIcon from '../../assets/images/icons/phone-call.svg';
import mainLogo from '../../assets/images/logo-white_alt.svg';
import Cta from './Cta';
import FooterContact from './FooterContact';
import WidgetItem from './WidgetItem';

function Footer() {
  const [input, setInput] = useState('');

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <footer className="footer-wrapper">
      <div className="container">
        <Cta />
        <div className="row gy-5 gy-lg-0">
          <div className="col-lg-3 col-md-6">
            <div className="footer-widget widget-about">
              <Link to="/" className="footer-logo">
                <img src={mainLogo} alt="Credom" />
              </Link>
              <p className="body-text light">
                BTW BE 0725.717.178 <br></br>
                IBAN BE 95 7350 5283 3258

              </p>
              <div className="widget-about-social">
                <a
                  href="https://www.instagram.com/dbaonderneming_bouw/"
                  className="social-link"
                >
                  <span className="social-icon">
                    <FaInstagram />
                  </span>
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100087923133877"
                  className="social-link"
                >
                  <span className="social-icon">
                    <FaFacebookF />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 d-flex justify-content-lg-center">
            <div className="footer-widget widget-services">
              <ul className="widget-menu">
                <WidgetItem linkUrl="/" linkText="Home" />
                <WidgetItem linkUrl="/overons" linkText="Over Ons" />
                <WidgetItem linkUrl="/#diensten" linkText="Diensten" />
                <WidgetItem linkUrl="/realisaties" linkText="Realisaties" />
                <WidgetItem linkUrl="/contact" linkText="Contact" />
                <WidgetItem linkUrl="/vacatures" linkText="Vacatures" />
              </ul>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-6 d-flex justify-content-lg-center">
            <div className="footer-widget widget-useful-links">
              <h3 className="widget-heading">Useful links</h3>
              <ul className="widget-menu">
                <WidgetItem linkUrl="/" linkText="About us" />
                <WidgetItem linkUrl="/" linkText="Requisites" />
                <WidgetItem linkUrl="/" linkText="Press contacts" />
                <WidgetItem linkUrl="/" linkText="FAQ page" />
                <WidgetItem linkUrl="/" linkText="IContact us" />
              </ul>
            </div>
          </div> */}
          {/* <div className="col-lg-3 col-md-6">
            <div className="footer-widget widget-subscribe">
              <h3 className="widget-heading">Subscribe</h3>
              <p className="body-text light">
                Subscribe to our newsletter! Stay always in touch!
              </p>
              <form action="#" className="subscribe-form">
                <div className="form-input-wrapper">
                  <input
                    type="text"
                    placeholder="Your Email"
                    className="form-input"
                    value={input}
                    onChange={handleChange}
                  />
                  <button
                    type="submit"
                    className="button subscribe-button"
                    onSubmit={handleSubmit}
                  >
                    <span className="button-icon">
                      <FaPaperPlane />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div> */}
        </div>
        <div className="footer-contact-wrapper">
          <div className="row">
            <FooterContact
              icon={phoneIcon}
              howToContact="Bel ons"
              text="+32 (0)494 88 91 28"
              texturl="tel:+32494889128"
            />
            <FooterContact
              icon={emailIcon}
              howToContact="Contacteer ons"
              text="info@dbaonderneming.be"
              texturl="mailto:info@dbaonderneming.be"
            />
            <div className="col-lg-4">
              <div
                className="footer-contact d-flex align-items-center"
                style={{ borderRight: 'none' }}
              >
                <span className="contact-icon">
                  <img src={mapIcon} alt="map" />
                </span>
                <div className="contact-info">
                  <span className="top-text">Locatie</span>
                  <p className="main-text">Breeërsteenweg 204, 3640 Kinrooi</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-text text-center">
          <p className="body-text light">
            &copy; 2023 DBA Bouw en Renovatie - Created by
            <a href="https://publilemon.be"> Publilemon</a>
          </p>
          <p className="body-text light">
            <Link to="/algemene-voorwaarden">Algemene voorwaarden</Link> - <Link to="/privacy-cookies">Privacy en cookies</Link>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
