import bannerBg from '../assets/images/DBA/PageHeaders/header_overons.jpg';
import About2 from '../components/About2';
import PageBanner from '../components/PageBanner';
import Process from '../components/Process';
import Team from '../components/Team';
import Vision from '../components/Vision';
import WhyUs from '../components/WhyUs';

function Overons() {
  return (
    <>
      <PageBanner
        bannerBg={bannerBg}
        shadowHeading="Over ons"
        mainHeading="Over ons"
        currentPage="Over ons"
      />
      <About2 />
      <Process />
    </>
  );
}

export default Overons;
