import React from 'react';
import { CgChevronDown, CgChevronLeft } from 'react-icons/cg';

const menuData = [
  {
    title: 'Home',
    path: '/',
  },

  {
    title: 'Over ons',
    path: '/overons',
  },

  {
    title: 'Diensten',
    path: '/#diensten',
  },

  {
    title: 'Realisaties',
    path: '/realisaties',
  },

  {
    title: 'Contact',
    path: '/contact',
  },

  {
    title: 'Vacatures',
    path: '#',

    iconClosed: <CgChevronLeft />,
    iconOpened: <CgChevronDown />,

    subNav: [
      {
        title: 'Ploegbaas / meestergast',
        path: '/vacatures#ploegbaas',
        cName: 'sub-nav',
      },
      {
        title: 'Metselaar',
        path: '/vacatures#metselaar',
        cName: 'sub-nav',
      },
      {
        title: 'Betonkister',
        path: '/vacatures#betonkister',
        cName: 'sub-nav',
      },
      {
        title: 'Allround arbeider',
        path: '/vacatures#allround',
        cName: 'sub-nav',
      },
      {
        title: 'Spontane sollicitatie',
        path: '/vacatures#spontaan',
        cName: 'sub-nav',
      },
    ],
  },

];

export default menuData;
