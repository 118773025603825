import bannerBg from '../assets/images/DBA/PageHeaders/header_overons.jpg';
import PageBanner from '../components/PageBanner';

function PrivacyCookies() {
  return (
    <>
      <PageBanner
        bannerBg={bannerBg}
        shadowHeading="Privacy en Cookies"
        mainHeading="Privacy en Cookies"
        currentPage="Privacy en Cookies"
      />

      <section className="numbers-wrapper about-two-wrapper section-padding">
        <div className="container">
          <div className="row align-items-center gy-5 gy-lg-0">
            <div className="col-lg-12">
              <div className="left-content">
                <div className="section-head">
                  <h2 className="section-title mb-20">
                    Privacy en Cookies
                    <span className="heading-dot" />
                  </h2>
                </div>

                <h2>WAT IS EEN COOKIE?</h2>
                <p className="body-text pr-80 mb-25">Een cookie is een klein tekstbestand dat tijdens het bezoek aan een website op uw computer wordt geplaatst. In dit tekstbestand wordt informatie opgeslagen. Deze informatie kan op een later bezoek worden uitgelezen door deze website. Sommige van deze cookies zijn noodzakelijk omdat de site het anders niet naar behoren kan functioneren. Andere cookies zijn handig voor de bezoeker. Bijvoorbeeld voor het veilig onthouden van uw gebruikersnaam en taalvoorkeur. Hierdoor hoeft u niet meer bij ieder bezoek deze informatie in te vullen.</p>
                <h3>WAAROM GEBRUIKT DBA Bouw ondernemeing&nbsp;COOKIES?</h3>
                <p className="body-text pr-80 mb-25">DBA Bouw ondernemeing&nbsp;maakt gebruik van cookies om u een betere gebruikerservaring aan te kunnen bieden geheel afgestemd op uw persoonlijke voorkeuren. Met behulp van cookies zorgt DBA Bouw ondernemeing&nbsp;ervoor dat u bijvoorbeeld niet herhaaldelijk dezelfde informatie ontvangt of moet invoeren. Tevens worden de cookies gebruikt voor het optimaliseren van de werking van de website. Hier kunt u denken aan het vergemakkelijken van het afrekenproces of om u te helpen nog sneller een specifiek object te vinden.</p>
                <p className="body-text pr-80 mb-25">Om uw persoongegevens zo goed mogelijk te beveiligen tegen verlies of tegen enige vorm van onrechtmatige verwerking heeft DBA Bouw ondernemeing&nbsp;adequate organisatorische en technische maatregelen genomen.</p>
                <p className="body-text pr-80 mb-25">Voor verdere informatie hierover zie het Privacy Statement.</p>

                <h3>HOE KUNT U COOKIES HANDMATIG UITZETTEN?</h3>
                <p className="body-text pr-80 mb-25">Cookies kunt u zeer eenvoudig handmatig in iedere browser uitschakelen. Let op: Als u de cookies uitschakelt, zullen uw gebruikersnamen en wachtwoorden op geen enkele website meer onthouden worden.</p>

                <h4 className='mt-25 mb-25'>FIREFOX:</h4>
                <ol><li>Open Firefox.</li><li>Klik op de ‘Alt’-toets op uw toetsenbord.</li><li>Kies in de menubalk die bovenaan in uw scherm verschijnt voor ‘Extra’ en daarna ‘Opties’.</li><li>Ga vervolgens naar het tabblad ‘Privacy’.</li><li>Kies na ‘Firefox zal’ voor ‘Aangepaste instellingen voor geschiedenis’. Vink hier uw voorkeuren aan en sla deze vervolgens op.</li></ol>

                <h4 className='mt-25 mb-25'>INTERNET EXPLORER:</h4>

                <ol><li>Open Internet Explorer.</li><li>Kies in het menu voor de optie ‘Extra’ en vervolgens de optie ‘Internet Opties’.</li><li>Klik op het tabblad ‘Privacy’.</li><li>Onder de knop ‘Advanced’ kunt u uw gewenste opties aanvinken en opslaan</li></ol>

                <h4 className='mt-25 mb-25'>GOOGLE CHROME:</h4>

                <ol><li>Open Google Chrome.</li><li>Klik op het gereedschap icoon.</li><li>Kies voor ‘Opties’.</li><li>Kies op het tabblad ‘Geavanceerde opties’ voor Instellingen voor inhoud.</li><li>Op het tabblad ‘Cookies’ kunt u vervolgens de aanbevolen opties aanvinken en opslaan.</li></ol>

                <h4 className='mt-25 mb-25'>SAFARI:</h4>

                <ol><li>Open Safari.</li><li>Kies ‘Voorkeuren’ in het taakmenu en klik op ‘Beveiliging’. (Het taakmenu bevindt zich rechtsboven in het Safari-venster en ziet eruit als een tandwiel.)</li><li>Vink in het gedeelte ‘Cookies accepteren’ aan of en wanneer Safari cookies van websites mag accepteren. Klik voor meer informatie over de opties op de knop met het vraagteken.</li><li>Als u meer wilt weten over de cookies die worden opgeslagen op uw computer, klikt u op ‘Cookies tonen’.</li>
                </ol>


              </div>

            </div>

          </div>
        </div>
      </section>

    </>
  );
}

export default PrivacyCookies;
