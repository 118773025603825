import { v4 as uuidv4 } from 'uuid';

const ServicesCardData = [
  {
    id: uuidv4(),
    heading: 'Project adaption',
    text: 'Adaptation of the finished project from the site.',
    background: '',
  },

  {
    id: uuidv4(),
    heading: 'Project adaption',
    text: 'Adaptation of the finished project from the site.',
    background: 'white',
  },

  {
    id: uuidv4(),
    heading: 'Project adaption',
    text: 'Adaptation of the finished project from the site.',
    background: 'white',
  },

  {
    id: uuidv4(),
    heading: 'Project adaption',
    text: 'Adaptation of the finished project from the site.',
    background: '',
  },

  {
    id: uuidv4(),
    heading: 'Project adaption',
    text: 'Adaptation of the finished project from the site.',
    background: 'white',
  },

  {
    id: uuidv4(),
    heading: 'Project adaption',
    text: 'Adaptation of the finished project from the site.',
    background: '',
  },
];

export default ServicesCardData;
