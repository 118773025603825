import { v4 as uuidv4 } from 'uuid';
import thumb1 from '../../assets/images/DBA/Testimonials/testimonial-img1.jpg';
import thumb2 from '../../assets/images/DBA/Testimonials/testimonial-img2.jpg';
import thumb3 from '../../assets/images/DBA/Testimonials/testimonial-img3.jpg';


const testimonialData = [
  {
    id: uuidv4(),
    heading:
      "Mooi idee! Professioneel resultaat",
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    clientName: 'Peter Flemmings',
    clientRole: 'Beringen',
    thumb: thumb1,
  },

  {
    id: uuidv4(),
    heading:
      "Mooi idee! Professioneel resultaat",
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    clientName: 'Peter Flemmings',
    clientRole: 'Beringen',
    thumb: thumb2,
  },

  {
    id: uuidv4(),
    heading:
      "Mooi idee! Professioneel resultaat",
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    clientName: 'Peter Flemmings',
    clientRole: 'Beringen',
    thumb: thumb3,
  },
];

export default testimonialData;
