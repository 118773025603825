import React from 'react';
import img1 from '../../assets/images/about/vision1.png';
import img2 from '../../assets/images/about/vision2.png';
import img3 from '../../assets/images/about/vision3.png';
import Progress from './Progress';

function Vision() {
  return (
    <section className="vision-wrapper">
      <div className="row">
        <div className="col-lg-6">
          <div className="row">
            <div className="col-sm-6">
              <div className="experience-card left-card">
                <h2 className="card-num card-heading">
                  20<span className="card-text">Years of work</span>
                </h2>
              </div>
            </div>
            <div className="col-sm-6">
              <div
                className="left-card bg-cover"
                style={{ backgroundImage: `url(${img1})` }}
              />
            </div>
            <div className="col-sm-6">
              <div
                className="left-card bg-cover"
                style={{ backgroundImage: `url(${img2})` }}
              />
            </div>
            <div className="col-sm-6">
              <div
                className="left-card bg-cover"
                style={{ backgroundImage: `url(${img3})` }}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="right-content">
            <div className="section-head">
              <span className="heading-bg">Vision</span>
              <h2 className="section-title mb-20">
                We build because we can
                <span className="heading-dot" />
              </h2>
              <p className="body-text">
                When constructing buildings for our customers, we use only
                modern building materials, the quality of which is confirmed by
                appropriate certificates and certificates. And all work is
                carried out on the basis of compliance with the existing
                building codes.
              </p>
            </div>

            <div className="vision-progress mt-30">
              <Progress heading="Starting Projects" label="75%" parcent="75" />
              <Progress heading="Landscape Design" label="42%" parcent="42" />
              <Progress heading="Interior decoration" label="63" parcent="63" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Vision;
