import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { HashLink as Link } from 'react-router-hash-link';
import ServicesCard from './ServicesCard';
import ServicesCardData from './ServicesCardData';

function ServicesSection() {
  return (
    <section id="diensten" name="diensten" className="services-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 p-2">
            <div className="section-head mt-40">
              <span className="heading-bg">Diensten</span>
              <h2 className="section-title">
                Diensten
                <span className="heading-dot" />
              </h2>
            </div>
          </div>
          {/* {ServicesCardData.map((item) => (
            <ServicesCard
              key={item.id}
              heading={item.heading}
              text={item.text}
              background={item.background}
            />
          ))} */}
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 p-2">
            <div className="section-head mt-40">
              <h3 className="section-title">
                Projectbouw
                <span className="heading-dot" />
              </h3>
            </div>
            <p className="body-text padding-right">
              Wij richten ons op het uitvoeren van grote en complexe bouwprojecten, zoals bijvoorbeeld appartementsgebouwen, woonwijken en commerciële gebouwen. Hierbij wordt gelet op kwaliteit, veiligheid en efficiëntie, en wordt er gebruik gemaakt van geavanceerde technologieën en methoden om het project op tijd en binnen budget af te ronden.
            </p>
            <Link to="/realisaties" className="button button-primary">
              Realisaties van professionele projecten&nbsp;
              <span className="icon">
                <FaArrowRight />
              </span>
            </Link>
          </div>
          <div className="col-lg-6 p-2">
            <div className="section-head mt-40">
              <h3 className="section-title">
                Particuliere woningbouw
                <span className="heading-dot" />
              </h3>
            </div>
            <p className="body-text padding-right">
              Wij richten ons op de bouw van privéwoningen, woningbouwprojecten voor particuliere klanten. Dit omvat zowel nieuwbouw als renovatie- en verbouwingsprojecten. Particuliere projectbouw richt zich op de specifieke wensen en eisen van de particuliere opdrachtgever, zoals de grootte, de locatie, de architectuur en de afwerking.
            </p>
            <Link to="/realisaties" className="button button-primary">
              Realisaties van particuliere projecten&nbsp;
              <span className="icon">
                <FaArrowRight />
              </span>
            </Link>
          </div>
          <div className="col-lg-6 p-2">
            <div className="section-head mt-40">
              <h3 className="section-title">
                Kelderbouw
                <span className="heading-dot" />
              </h3>
            </div>
            <p className="body-text padding-right">
              Wij richten ons op de bouw van kelders in gebouwen. Het omvat het graven van een put, het aanbrengen van funderingen en het bouwen van de muren en plafonds van de kelder. Een goede afwatering en ventilatie is een must om vocht- en schimmelproblemen te voorkomen.
            </p>
            <Link to="/realisaties" className="button button-primary">
              Realisaties van kelderbouw&nbsp;
              <span className="icon">
                <FaArrowRight />
              </span>
            </Link>
          </div>
          <div className="col-lg-6 p-2">
            <div className="section-head mt-40">
              <h3 className="section-title">
                Projectopvolging/begeleiding
                <span className="heading-dot" />
              </h3>
            </div>
            <p className="body-text padding-right">
              Wij richten ons op projectopvolging en begeleiding. Op vraag van de klant kunnen we een ruwbouw wind- en waterdicht aanbieden, evenals dakwerken en ramen. Ons doel bij projectopvolging/begeleiding is om ervoor te zorgen dat alle aspecten van het project voldoen aan de hoogste normen op het gebied van waterdichtheid, veiligheid en energie-efficiëntie.
            </p>
            <Link to="/realisaties" className="button button-primary">
              Realisaties van dakwerken&nbsp;
              <span className="icon">
                <FaArrowRight />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServicesSection;
