import React from "react"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import PortfolioProjectItem from './PortfolioProjectItem'
import realisatiesData from './realisatiesData'
import SimpleReactLightbox from "simple-react-lightbox"

function PortfolioProject() {
  return (
    <div className="portfolio-gallery-wrapper section-padding">
      <div className="container mb-50">
        <div class="section-head text-center">
          <h2 class="section-title">Onze realisaties<span class="heading-dot"></span></h2>
        </div>
      </div>
      {/* Projectbouw */}
      <div className="gallery-items-wrapper">
        {realisatiesData.map((project, index) => (
          <SimpleReactLightbox key={project.id}>
            <div className={`item-${index + 1}`}>
              <PortfolioProjectItem
                name={project.name}
                subTitle={project.subTitle}
                thumbImage={project.images.shift()}
                images={project.images}
                item={project.item}
              />
            </div>
          </SimpleReactLightbox>
        ))}
      </div>
    </div>
  )
}

export default PortfolioProject
