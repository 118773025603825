import React, { useState } from "react"

function ContactForm() {
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [message, setMessage] = useState("")

	const [formSuccess, setFormSuccess] = useState(null)
	const submitHandler = (e) => {
		e.preventDefault()

		let myForm = document.getElementById("contactForm")
		let formData = new FormData(myForm)
		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: new URLSearchParams(formData).toString(),
		}).then(
			() => console.log("Form successfully submitted"),
			setFormSuccess(true)
		)
	}

	return (
		<div>
			{!formSuccess ? (
				<form
					onSubmit={(e) => submitHandler(e)}
					className="contact-form"
					id="contactForm"
					method="POST"
					name="contact"
					data-netlify="true"
				>
					<input type="hidden" name="form-name" value="contact" />
					<input
						type="hidden"
						id="subject"
						name="subject"
						value="Contactformulier - DBA Bouw en Renovatie"
					/>
					<h3 className="form-heading heading-2 white mb-20">
						Contactformulier
					</h3>
					<input
						name="naam"
						type="text"
						placeholder="Naam"
						className="form-field"
						required
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<input
						name="email"
						type="email"
						placeholder="E-mail"
						className="form-field"
						required
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<textarea
						name="bericht"
						placeholder="Bericht"
						className="form-text-field"
						required
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					/>
					<button type="submit" className="button button-primary mt-10">
						Verstuur
					</button>
				</form>
			) : (
				<div>
					Bedankt voor je bericht.
				</div>
			)}
		</div>
	)
}

export default ContactForm
