import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import aboutImg from '../../assets/images/DBA/Home/about-img.png';
import aboutBg from '../../assets/images/illustration/about-bg.svg';
import ListItem from './ListItem';

function About1() {
  return (
    <section
      className="about-wrapper section-padding bg-cover pb-0"
      style={{ backgroundImage: `url(${aboutBg})` }}
    >
      <div className="container">
        <h2 className="mb-5 text-center">15 jaar ervaring, één missie:<br></br>uw bouwdromen werkelijkheid maken!</h2>
        <div className="row">
          <div className="col-lg-6">
            <div className="left-content bg-cover">
              <img src={aboutImg} alt="men" className="img-fluid" />
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center">
            <div className="right-content">
              <div className="section-head">

                <h2 className="section-title">
                  Welkom bij DBA-Onderneming!
                  <span className="heading-dot" />
                </h2>
              </div>
              <p className="body-text">
                Met 15 jaar ervaring in de bouwsector, staan wij garant voor
                kwalitatieve en efficiënte projecten. Vanaf 2019 hebben wij een
                grote sprong gemaakt in de projectbouw, waaronder
                appartementsgebouwen, woningwijken en particuliere woningen.
              </p>
              {/* <ul className="right-content-items">
                <ListItem listText=" Simplicity of form. Almost every house has a flat roof." />
                <ListItem listText="  Thoughtful lighting. Often, buildings provide panoramic." />
                <ListItem listText="  Original color. Before building a house, the future." />
              </ul> */}
              <Link to="/overons" className="button button-primary mt-4">
                Over ons
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About1;
