import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function InteriorCard({ cardBg, shadaowHeading, mainHeadidng, desc, cardClr }) {
  return (
    <div className="col-lg-6">
      <div
        className="interior-content bg-cover"
        style={{ backgroundImage: `url(${cardBg})` }}
      >
        <div className="interior-overlay" />
        <div className="section-head">
          <span className="heading-bg">{shadaowHeading}</span>
        </div>
        <div
          className={
            cardClr === 'light'
              ? 'interior-card card-2'
              : 'interior-card card-1'
          }
        >
          <h3 className="card-heading">{mainHeadidng}</h3>
          <div className="body-text">{desc}</div>
          <Link to="services-details" className="button button-minimal">
            Learn More{' '}
            <span className="icon">
              <i className="fas fa-arrow-right" />
              {/* <FaArrowRight /> */}
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default InteriorCard;
