import React from "react"
import Cookies from "js-cookie"

function CookieConsent() {
	let showCookieModal = Cookies.get("cookieconsent") == null;

	function accept() {
		let cookieConsent = Cookies.get("cookieconsent")
		if (cookieConsent != "accept") {
			Cookies.set("cookieconsent", "accept")
      document.querySelector("#cookieModal").remove();
		}
	}

	return (
		<div>
			{showCookieModal ? (
				<div
					class="cc-window cc-floating cc-type-info cc-theme-block cc-bottom cc-left"
          id="cookieModal"
					aria-describedby="cookieconsent:desc"
					aria-label="cookieconsent"
					aria-live="polite"
					role="dialog"
					style={{ backgroundColor: "#eb6c44", color: "#ffffff" }}
				>
					<span class="cc-message">
						Deze website gebruikt cookies om de beste ervaring te garanderen.
						<a
							href="/privacy-cookies"
							class="cc-link"
							aria-label="learn more about cookies"
							rel="noopener noreferrer nofollow"
							role="button"
							tabindex="0"
							target="_blank"
						>
							Meer informatie
						</a>
					</span>
					<div class="cc-compliance">
						<a
							class="cc-btn cc-dismiss"
							aria-label="dismiss cookie message"
							role="button"
							tabindex="0"
							style={{ backgroundColor: "#f5d948", color: "#000", borderStyle: "none" }}
							onClick={accept}
						>
							Aanvaarden
						</a>
					</div>
				</div>
			) : (
				""
			)}
		</div>
	)
}

export default CookieConsent
